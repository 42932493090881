<template>
  <div v-if="$store.getters.getSiteInfo">
    <div>
      <div class="top_img-size">
        <img :src="adslist.localimg" alt @click="adsClick" />
      </div>
      <!-- 顶部栏 -->

    <div class="top_case">
      <div class="top_case_top" style="width:77%;">
<div style="width:100%;white-space: nowrap;display: flex;">

        <div class="top_case-one">
          <el-card class="box-card" style="background-color:#0ff">
            <div class="text item infodiv">
              <div class="infodiv-item">
		<img :src="$store.getters.getUserInfo.avatar" class="infoimg">
              </div>
              <div class="infodiv-item">
		<div class="top_case_title">HI,{{ $store.getters.getUserInfo.username }}</div>
		<div class="infodiv-font">
		  欢迎使用{{ $store.getters.getSiteInfo.name }}系统！
		</div>
              </div>
	    </div>
            <div class="text item">
              <span class="item-l1">{{ $store.getters.getUserInfo.group_text }}</span>
		<router-link tag="a" :to="'/account/base'">
		  <span class="item-r1">编辑资料</span>
	        </router-link>
	    </div>

          </el-card>
        </div>

        <div class="top_case-two">
          <el-card class="box-card" style="background-color:#F8F8F8">
            <div slot="header" class="casedv">
              <span class="top_case_title">我的余额</span>
            </div>
	    <div class="text item codeInfo">
		<span class="mflag">¥</span><span class="money">{{ balance }}</span>
            </div>
            <div class="text item" style="text-align:center;">
		<router-link tag="a" :to="'/account/recharge'"><span class="item-l">充值</span></router-link>
		<router-link tag="a" :to="'/account/capitalFlow'"><span class="item-r">明细</span></router-link>
	    </div>

          </el-card>
        </div>

        <div class="top_case-four">
          <el-card class="box-card">
            <div slot="header" class="casedv">
              <span class="top_case_title" style="color:#FF0000;">推荐好友，永久获每单0.05元</span>
            </div>
	    <div class="text item codeInfo">
		<span class="mt">我的佣金</span>:<span class="mflag">¥</span>
		<span class="money">{{ money }}</span>
            </div>
            <div class="text item" style="text-align:center;">
		<router-link tag="a" :to="'/account/cashout'"><span class="item-l">去提现</span></router-link>
		<router-link tag="a" :to="'/account/InviteFriends'"><span class="item-r2">分享本站给好友</span></router-link>
	    </div>
          </el-card>
        </div>
</div>

<!-- 上面部分 结束 -->

<!-- 中上部分 开始 -->

        <div class="liuc" style="background-color:#f63;margin-left:20px;">
          <div class="items" @click="$router.push('/orderAdmin/purchase')">
            <div class="item_in">
              <img src="~assets/icons/fh.png" alt />
              <p>立即发货</p>
            </div>
          </div>

          <div class="items" @click="$router.push('/orderAdmin/orderList')">
            <div class="item_in">
              <img src="~assets/icons/dd.png" alt />
              <p>订单管理</p>
            </div>
          </div>

          <div class="items" @click="$router.push('/orderAdmin/parcelList')">
            <div class="item_in">
              <img src="~assets/icons/didan.png" alt />
              <p>包裹管理</p>
            </div>
          </div>

          <div class="items" @click="$router.push('/workOrder/myOrder')">
            <div class="item_in">
              <img src="~assets/icons/sh.png" alt />
              <p>售后管理</p>
            </div>
          </div>

          <div class="items" @click="$router.push('/workOrder/orderSumbit')">
            <div class="item_in2">
              <img src="~assets/icons/didan.png" alt />
              <p>底单申请</p>
            </div>
          </div>

        </div>
<!-- 中上部分 结束 -->

     </div>

        <div class="top_case-three">
          <el-card class="box-card">
            <div slot="header" class="clearfix" style="border-bottom:1px solid #EEE;">
              <span class="title-oblong"></span>
              <span class="title" style="font-weight: 600">专属客服</span>
            </div>
            <div class="top_case-code">
              <img class="img" :src="$store.getters.getSiteInfo.wechat_service" />
              <div class="img-text">请扫码,加微信</div>
            </div>

          </el-card>
        </div>
     </div>
      
      <!-- 中间部分 开始 -->

      <div class="bottom">
        <div class="bottom-content">
          <div class="bottom-item">
            <div>下单总数</div>
            <div class="font">
              <span style="color: red">{{ packInfo.order_num }}</span>个
            </div>
          </div>
          <div class="bottom-item">
            <div>待支付</div>
            <div class="font">
              <span>{{ packInfo.first }}</span>笔
            </div>
          </div>
          <div class="bottom-item">
            <div>打单中</div>
            <div class="font">
              <span>{{ packInfo.second }}</span>笔
            </div>
          </div>
          <div class="bottom-item">
            <div>已出单</div>
            <div class="font">
              <span>{{ packInfo.third }}</span>笔
            </div>
          </div>
          <div class="bottom-item">
            <div>异常</div>
            <div class="font">
              <span>{{ packInfo.forth }}</span>笔
            </div>
          </div>
          <div class="bottom-item">
            <div>取消</div>
            <div class="font">
              <span>{{ packInfo.five }}</span>笔
            </div>
          </div>
          <div class="bottom-item">
            <div>总包裹数</div>
            <div class="font">
              <span>{{ packInfo.package_num }}</span>个
            </div>
          </div>
        </div>
      </div>

      <!-- 中间部分 结束 -->

<!-- 页底信息部分 开始 -->

    <div class="bottom_case">
      <div class="bottom_case_left">

          <el-card class="box-card">
            <div slot="header" class="clearfix2">
              <span class="title-oblong"></span>
              <span class="title" style="font-weight: 600">网站公告</span>
              <div style="float:right;"><a href="/noticelist" target="_blank">更多>></a></div>
            </div>
            <div class="list">
              <ul>
                <li v-for="(nItem, nKey) in noticeHot">
                  <a href="javascript:;"  @click="showNotice(nItem.id)">{{ nItem.title }}</a><span>{{ timeConverDate(nItem.createtime) }}</span>
                </li>
              </ul>
            </div>

          </el-card>
      </div>

      <div class="bottom_case_right">
          <el-card class="box-card">
            <div slot="header" class="clearfix2" style="padding-bottom:5px;border-bottom:2px solid #ff0000;">
              <span class="title-oblong"></span>
              <span class="title" style="font-weight: 600">常见问题</span>
              <div style="float:right;"><a href="/noticelist" target="_blank">更多>></a></div>
            </div>
            <div class="list">
              <ul>
                <li v-for="(nItem, nKey) in noticeQuestion">
                  <a href="javascript:;"  @click="showNotice(nItem.id)">{{ nItem.title }}</a><span>{{ timeConverDate(nItem.createtime) }}</span>
                </li>
              </ul>
            </div>

          </el-card>
      </div>

    </div>

<!-- 显示公告详情 -->
    <el-dialog  :title="noticeDetail.title" :visible.sync="noticeDivShow" center :before-close="noticeDivClose" width="880px" top="100px">
      <el-div :model="noticeInfo" :label-position="labelPosition" label-width="100px" style="display: flex; flex-direction: column; align-items: center;padding:0 10px;">

        <div class="info">
          <div class="info-content" v-html="noticeDetail.content"></div>
        </div>
        <div class="addtime">
          <span>发布时间：{{timeConverDate(noticeDetail.createtime)}}</span><span class="sdf" style="margin-left:15px">{{$store.getters.getSiteInfo.name}}</span>
        </div>
      </el-div>
    </el-dialog>
<!-- 页底信息部分 结束 -->

      <!-- 充值 -->
      <div class="recharge">
        <el-dialog :visible.sync="redialogVisible" :before-close="handleClose" width="60%">
          <div class="recharge-hint">
            <div>使用须知</div>
            <p>
              1.请使用支付宝扫描左侧收款码进行付款,付款前请核对收款人信息:<span style="color: red">&nbsp;{{ $store.getters.getSiteInfo.alipay_account }}</span>
            </p>
            <p>2.在付款页面备注一栏填写您的登录账号,可实现自动到账!如未备注账号,可从支付宝付款详情中手动复制20开头的订单号,手动提交订单号也可自动到账.</p>
            <p>3.平台暂不支持退款,请根据需要充值.</p>
          </div>
          <div class="recharge-title"><span class="recharge-title-tag" style="color: red">|</span> <span class="recharge-title-text" style="color: black">账户充值</span></div>
          <div class="recharge-main">
            <div class="recharge-main-img"><img :src="imgUrl" /></div>
            <div class="recharge-main-right">
              <div class="main-right-balance">
                <i class="el-icon-s-cooperation"></i><span>账户可用金额</span>
                <p>¥{{ balanceInfo.balance }}</p>
                <div class="main-right-freshen">
                  <el-button size="mini" plain @click="renovate" style="background-color: white; border-color: red; color: red; font-size: 14px">刷新查看余额</el-button>
                </div>
              </div>
              <el-input v-model="input" placeholder="请输入支付宝订单号"></el-input>
              <el-button style="width: 100%; margin-top: 20px" type="danger" @click="submit">提交充值</el-button>
            </div>
          </div>
        </el-dialog>
      </div>
      <!-- 弹窗 -->
      <el-dialog data-v-0c77fe6e :title="loadNoticeByPos.title" :visible.sync="dialogVisible" :modal-append-to-body="false" :lock-scroll="false" width="40%" custom-class="el-dialog1" :center="true" v-show="isshow" :modal="false">
        <div v-html="loadNoticeByPos.content"></div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="next" size="small">下次再说</el-button>
          <el-button data-v-652cfc00 type="primary" @click="mesg" size="small">我知道了</el-button>
        </span>
      </el-dialog>
      <!-- <dialog></dialog> -->
      <app-main />
    </div>
  </div>
</template>

<script>
import { timeConversion, timeConverDate } from 'utils/utils'
import { moneyInfo, getSubTrad, getNoticeDetail, getTopNotice, getNoticeSelect, loadNoticeByPos, statusNum, inviteHttp, selectAll, deliverPrice, groupList, getsuperadsbytag, getAward } from 'network/api'
import AppMain from '../../../components/baseStructure/AppMain.vue'
import TopUp from '../../../components/TopUp.vue'
import Cookies from 'js-cookie'
export default {
  inject: ['reload'],
  components: {
    AppMain,
    TopUp
  },
  data () {
    return {
      o: '\u00a0\u00a0\u00a0\u00a0\u00a0',
      balance: '',
      // 公告
      notice: [],
      noticeTop: [],
      // 底部订单状态
      packInfo: {},
      // 邀请框
      url: '',
      language: '我发现一个电商人必备的礼品代发网站',
      people: '',
      arr: [],
      // 立即充值
      activeIndex: '0',
      dialogVisible: false,
      amountType: 'alipay',
      controls: false,
      num: undefined,
      gridData: [],
      dialogTableVisible: false,
      redialogVisible: false,
      imgUrl: '',
      input: '',
      balanceInfo: {},
      money: 0,
      styleObject: {
        color: 'red'
        // fontSize: '13px'
      },
      serverName: '',
      adslist: '',
      clickhref: '',
      groupData: '',
      awardName: null,
      awardUp: '',
      m: '000.00',
      loadNoticeByPos: [],
      isshow: true,
      noticeDetail: [],
      noticeDivShow: false,
      noticeId: 0,
      labelPosition:{},
      noticeInfo:{},
      noticeHot:{},
      noticeQuestion:{}
    }
  },
  created () {
    getAward().then((res) => {
      if (res.code !== 1) return
      this.awardName = res.data
    })
    // 顶部广告
    getsuperadsbytag({ tagname: 'purchase' }).then((res) => {
      if (res.code !== 1 && res.data.length === '') {
        return
      }
      if(res.data.adslist[0]){
        this.adslist = res.data.adslist[0]
        this.clickhref = res.data.adslist[0].clickhref
      }
    })
    // 余额
    this.getMoney()
    // 头部公告
    getTopNotice().then((res) => {
      this.noticeTop = res.data[0]
      this.notice = res.data
      this.notice.shift()
    })
    // 常见问题
    getNoticeSelect({tids: [2,3,4], pos: 2}).then((res) => {
      // this.noticeTop = res.data[0]
      this.noticeQuestion = res.data.data
      // this.notice.shift()
    })
    // 网址公告
    getNoticeSelect({typedata: 1, pos: 2}).then((res) => {
      // this.noticeTop = res.data[0]
      this.noticeHot = res.data.data
      // this.notice.shift()
    })
    // 订单状态
    statusNum()
      .then((res) => {
        // console.log(res, '----')
        if (res.code !== 1 || undefined) {
          return false
        }
        if (res.code === 1) {
          this.packInfo = res.data
        }
      })
      .catch((err) => {
        console.log(err)
      })

    // 邀请链接
    inviteHttp().then((res) => {
      if (res.code !== 1 && res.data.length === '') {
        return
      }
      // eslint-disable-next-line camelcase
      const frontend_url = this.$store.getters.getSiteInfo.frontend_url
      // console.log(this.$store.getters.getUserInfo)
      // eslint-disable-next-line camelcase
      this.url = this.language + frontend_url + '/register?user_id=' + res.data
      // console.log(this.url)
    })
    // 邀请用户信息
    selectAll().then((res) => {
      if (res.code === 0) {
        // eslint-disable-next-line no-return-assign
        return (this.people = 0)
      }
      this.people = res.data.length
    })
  },
  computed: {
    // 注册时间戳转换
    createtime () {
      return timeConversion(this.$store.getters.getUserInfo.createtime)
    },
    compute () {
      return Object.keys(this.$store.getters.getSiteInfo.server_server)[0]
    },
    comput () {
      return Object.values(this.$store.getters.getSiteInfo.server_server)[0]
    },
    // 使用时间戳转化函数
    timeConverDate() {
      return timeConverDate
    }
  },
  methods: {
    setcookie () {
      const d = new Date()
      d.setTime(d.getTime() + 6 * 60 * 60 * 1000)// 24小时
      // d.setTime(d.getTime() + 1 * 60 * 60 * 1000);//1小时
      // d.setTime(d.getTime() + 1 * 60 * 1000);//1分钟
      // ad=popup-ad   键值对形式：name=key   expires 有效期
      document.cookie = 'ad=popup-ad;expires= ' + d.toGMTString()
      const result = document.cookie
      return result
    },
    // 显示公告详情
    showNotice(id) {
      this.noticeId = id
      getNoticeDetail({id:this.noticeId}).then((res) => {
        this.noticeDetail = res.data
        this.noticeDivShow = true
      })
    },
    /*  关闭公告详情页 testarea   */
    noticeDivClose(){
      this.noticeDivShow = false
    },
    dialog () {
      loadNoticeByPos({
        pos: '2'
      }).then((res) => {
        this.loadNoticeByPos = res.data
        this.dialogVisible = true
      })
    },
    mesg () {
      this.dialogVisible = false
      window.localStorage.setItem('mesg', '我知道了')
    },
    next () {
      this.dialogVisible = false
      localStorage.removeItem('mesg')
      Cookies.remove('ad')
    },
    getMoney () {
      moneyInfo().then((res) => {
        // eslint-disable-next-line no-cond-assign
        this.balance = res.data.balance
        this.money = res.data.ok_money
      })
    },
    goUp () {
      this.$router.push('/account/balance')
    },
    // activeItem () {
    refresh () {
      // console.log('余额')
      // this.reload()
      // this.refresh()
      this.getMoney()
    },
    // setTimeout(function () {
    //   this.reload()
    // }, 1000)
    // },
    // 提交充值按钮
    submit () {
      getSubTrad({ trade: this.input }).then((res) => {
        if (res.code === 1) {
          this.$message({
            message: '充值成功',
            type: 'success'
          })
        } else {
          return this.$message.error({
            message: res.msg
          })
        }
      })
    },
    renovate () {
      moneyInfo().then((res) => {
        if (res.code === 1) {
          this.balanceInfo = res.data
          // console.log(res.data)
          this.$message({
            message: '刷新成功',
            type: 'success'
          })
        } else {
          this.$message.error({
            message: '刷新失败'
          })
        }
        // console.log(res)
      })
    },
    recharge () {
      this.redialogVisible = true
      this.imgUrl = this.$store.getters.getSiteInfo.alipay_paymentcode
    },
    // 广告点击跳转
    adsClick () {
      // window.open(this.clickhref)
    },
    // 复制网址成功
    onCopy (e) {
      this.$message.success('复制成功')
    },
    // 更换邀请语
    change () {
      this.arr = this.$store.getters.getSiteInfo.invite_title || ''
      const index = Math.floor(Math.random() * this.arr.length)
      this.language = this.arr[index]
      inviteHttp().then((res) => {
        // eslint-disable-next-line camelcase
        const frontend_url = this.$store.getters.getSiteInfo.frontend_url
        // eslint-disable-next-line camelcase
        this.url = this.language + ',' + frontend_url + '/register?user_id=' + res.data
      })
    },
    // 马上充值
    showRecharge () {
      this.dialogVisible = true
    },
    closePayDialog () { },
    load () {
      deliverPrice().then((res) => {
        if (res.code === 1) {
          this.gridData = res.data
          // for (let i = 0; i < res.length; i++) {
          // }
        }
        // console.log(res)
      })
      groupList().then((res) => {
        if (res.code === 1) {
          this.groupData = res.data
          // for (let i = 0; i < res.length; i++) {
          // }
        }
        // console.log(res)
      })
    },
    handleClose (done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          done()
        })
        .catch((_) => { })
    }
  },
  mounted () {
    this.load()
    const vers = localStorage.getItem('mesg')
    if (!vers) {
      if (!document.cookie.includes('ad=')) {
        this.setcookie()
        this.dialog()
        this.isshow = true
      } else {
        this.isshow = false
      }
    }
  }
}
</script>

<style  lang="scss" scope>
.el-button--small,
[data-v-652cfc00] .el-button--small.is-round {
  padding: 9px 15px;
  width: 100px;
}
.itmup {
  position: relative;
  left: -280px;
  margin: 0 10px;
}
.awardUp {
  position: relative;

  // text-align: right;
  // display: block;
  // margin-left:2%;

  margin-top: 50px;
  // top: -30%;
  // font-size: 14px;
  // margin-top: 50px;
  // margin: 50 10px;
}
// ---
// .el-dialog__body {
//   padding: 30px 20px;
//   color: #606266;
//   font-size: 14px;
//   word-break: break-all;
//   // height: 600px;
//   max-height: 600px;
//   overflow-y: auto;
// }
.el-dialog {
    display: table;
}
.el-dialog__body {
  border-top:1px solid #CCC;
}
.textLive1 {
  font-weight: 600;
  //  margin-left: 25%;
  margin-top: -20px;
  font-size: 18px;
  // text-align:center;
}
.textLive {
  font-weight: 600;
  color: #ee7e31;
  margin-left: 15%;
  padding-bottom: 20px;
  font-size: 18px;
  margin-top: -28px;
}
.groupData {
  margin-top: 30px;
  // position: relative;
  // text-align: center;
  float: left;
  margin-left: 12%;
  font-size: 16px;
  justify-content: space-between;
  //  position: inline-block;
  color: #1890ff;
}

.text.item {
  position: relative;
}
.el-icon-refresh {
  cursor: pointer;
  font-size: 20px;
  margin-left: 20px;
  color: #1890ff;
}
//顶部图片
.top_img-size {
  img {
    width: 100%;
    height: 80px;
  }
}
//顶部栏
.top_case {
  white-space: nowrap;
  display: flex;
  width: 100%;
  margin: {
    top: 20px;
    right: 20px;
  }
  &-one {
    margin-left: 20px;
    width: 33%;
  }
  &-two {
    width: 33%;
    .top_case-code {
      display: flex;
      flex-direction: column;
    }
  }
  &-four {
    width: 33%;
    .top_case-code {
      display: flex;
      flex-direction: column;
    }
  }
  &-three {
    background-color:#F4F4F4;
    margin-left: 15px;
    width: 20%;
    .notice-item-one {
      font-size: 18px;
      font-weight: 600;
      line-height: 50px;
      color: #db98f0;
      white-space: nowrap;
      max-height: 200px;
      overflow: auto;
      span {
        color: red;
        margin: 10px;
        padding-right: 15px;
      }
    }
    img {
      width: 180px;
      height: 180px;
    }
    .img-text{
      text-align: center;
	backgroun-color:#f2f2f2;
	padding-top:6px;
    }
  }
}

/* 中间栏 */
.center {
  // width: 100%;
  display: flex;
  margin-left: 20px;
}
.center-right {
  width: 33%;
  height: 100%;
  // margin: 16px;
  margin-top: 30px;
  margin-left: 30px;
  background-color: white;
  border-radius: 10px;
  .el-input__inner {
    margin: 8px 15px 0px 15px;
    width: 90%;
    height: 30px;
  }
  .btn {
    margin: 8px 15px 0px 15px;
  }
  .text {
    margin: 5px 15px 5px 15px;
  }
}
//渐变框
.liuc {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 98%;
  height: 88px;
  background: linear-gradient(308deg, #fda284, #f7d167);
  border-radius: 4px;
  box-sizing: border-box;
  margin: 15px 0;
  color: #fff;

  .items {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 22px 0;
    flex: 1;
    cursor: pointer;
    transition: all 0.2s;

    .item_in {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-right: 1px solid hsla(0, 0%, 100%, 0.65);
      box-sizing: border-box;
      margin-top: 1px;
      p {
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.45);
        font-weight: bold;
      }
    }
    .item_in2 {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      margin-top: 1px;
      p {
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.45);
        font-weight: bold;
      }
    }

    &:hover {
      cursor: pointer;
      background: #0002;
    }
  }

  img {
    // border-radius: 4px;
    width: 25px;
    height: 25px;
  }
}
//底部栏
.bottom {
  margin: 15px 20px;
  width: 97%;
}
.bottom-content {
  height: 80px;
  background: #fff;
  box-shadow: 0 2px 20px rgba(15, 41, 131, 0.1);
  margin-bottom: 24px;
  padding: 32px 0;
  display: flex;
}
.bottom-item {
  color: gray;
  font-size: 14px;
  padding-left: 40px;
  width: 14%;
  border-right: 1px solid #f0f0f0;
  .font {
    color: black;
  }
  span {
    color: black;
    font-size: 28px;
    margin-right:3px;
  }
}
/* 卡片组件自带样式 */
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 10px;
}
.clearfix2 {
  padding-top:5px;
  padding-bottom:5px;
  border-bottom:2px solid #ff3300;
}
.clearfix {
  display: flex;
  padding-top:5px;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.clearfix .title{
    font-size:18px;
}

/* 自定义卡片样式 */
.el-card__header {
  border-bottom: none;
}
.el-card__body {
  padding-top: 0px;
  padding-left: 25px;
}
.el-card,
.el-message {
  border-radius: 0px;
}
.el-card.is-always-shadow,
.el-card.is-hover-shadow:focus,
.el-card.is-hover-shadow:hover {
  box-shadow: none;
}
.el-card {
  border: none;
  // width: 540px;
  margin-bottom:0px;
  padding-bottom:0px;
}
.title-oblong {
  float:left;
  display: block;
  margin-top: 3px;
  margin-right: 5px;
  color: #1a91ff;
  height: 20px;
  width: 6px;
  background-color: #1a91ff;
}
/* 充值Dialog */
.content-dialog {
  padding-left: 58px;
  padding-right: 58px;
}

.content-dialog .name {
  margin-bottom: 1em;
}

.content-dialog .price {
  margin-bottom: 1em;
  display: flex;
  align-items: center;
}

.content-dialog .price .packing {
  display: flex;
}

.content-dialog .price .packing .packing-item {
  cursor: pointer;
  width: 80px;
  height: 32px;
  border: 1px solid #d9d9d9;
  margin-right: 16px;
  border-radius: 4px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-dialog .price .packing .active {
  position: relative;
  border: 1px solid #1890ff;
}

.content-dialog .price .packing .packing-item.active .dagou {
  display: block;
}

.content-dialog .price .packing .packing-item .dagou {
  position: absolute;
  right: -1px;
  bottom: -1px;
  display: none;
}

.input-num {
  margin-left: 70px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
}

.input-num .queding {
  color: #1890ff;
  cursor: pointer;
  padding: 0 8px;
}

.payway-btn {
  display: flex;
  align-items: center;
}

.payway-btn span {
  height: 20px;
}
.way {
  display: flex;
}

.payway {
  cursor: pointer;
  margin-right: 16px;
  width: 120px;
  height: 32px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  display: flex;
  align-items: center;
  position: relative;
}

.payway .yue {
  margin: 0 8px;
}

.payway .dagou {
  display: none;
}

.payway.active {
  border: 1px solid #1890ff;
}

.payway.active .dagou {
  position: absolute;
  right: -1px;
  bottom: -1px;
  display: block;
}

.btn-pay {
  display: inline-block;
  background-color: #f93244;
  color: #fff;
  cursor: pointer;
  width: 120px;
  height: 32px;
  border-radius: 4px;
  line-height: 32px;
  text-align: center;
  margin-top: 24px;
}
</style>
<style scoped lang="scss">
$colors: #e8e8e8;
$colour: red;
@mixin public($colors) {
  color: $colors;
}
@mixin titles() {
  font: {
    size: 16px;
    weight: 600;
  }
}
.recharge-hint {
  border: 1px solid $colors;
  padding: 15px 15px 0 15px;
  div {
    padding-bottom: 15px;
    font-weight: 600;
    color: black;
    font: {
      size: 16px;
      weight: 600;
    }
  }
  p {
    padding-bottom: 10px;
    @include public(black);
  }
}
.recharge-title {
  margin-top: 15px;
  &-tag {
    @include titles();
  }
  &-text {
    @include titles();
  }
}
.recharge-main {
  display: flex;
  &-img {
    padding: 5% 10% 10% 10%;
    border-right: 1px solid $colors;
    img {
      width: 200px;
      height: 200px;
      border-radius: 10px;
      border: 5px solid #ee7e31;
    }
  }
  &-right {
    width: 100%;
    padding: 40px;
    .main-right-balance {
      padding: 10px 20px 10px 15px;
      background-color: #fff6f7;
      border: 1px solid $colour;
      border-radius: 10px;
      i {
        font-size: 30px;
        color: $colour;
      }
      p {
        color: $colour;
        font-weight: 600;
        margin-top: 10px;
      }
      .main-right-freshen {
        float: right;
        position: relative;
        top: -40px;
      }
    }
  }
}

.rechargeDialog .el-dialog__header,
.tixianDialog .el-dialog__header {
  border-bottom: 1px solid $colors;
}

.input-num .el-input-number--small {
  width: 180px;
}

.input-item.number .el-input-number {
  width: 324px;
}
</style>

<style lang="scss" scoped>
.el-card__header {
    padding: 18px 20px;
    border-bottom: 1px solid #EBEEF5;
    box-sizing: border-box;
}
/*
.top_case-one, .top_case-two, .top_case-four {
    width: 33%;
}*/
.top_case-shadow {
  box-shadow: 0 2px 20px rgba(15, 41, 131, 0.1);
}
.top_case-code{
    text-align: center;
}
.top_case-code .img2{
    width:160px;
    height:160px;
    text-align: center;
}
.money,.mt,.mflag{
    font-size:20px;
    height:26px;
    line-height:26px;
}
.codeInfo{ padding-bottom:29px; padding-left:35px;}
.mflag{	padding:0 3px 0 6px;	}
.item-l1 {
    background-color:#73aa00;
    color:#FFF;
    font-size:16px;
    padding: 10px 20px;
    margin-right:25px;
    border-radius: 5px;
}
.item-r1 {
    background-color:#0099cc;
    color:#FFF;
    font-size:16px;
    padding: 10px 20px;
    border-radius: 5px;
}
.item-l {
    background-color:#3399cc;
    color:#FFF;
    font-size:16px;
    padding: 10px 20px;
    margin-right:25px;
    border-radius: 5px;
}
.item-r,.item-r2 {
    color:#FFF;
    font-size:16px;
    padding: 10px 20px;
    border-radius: 5px;
}
.item-r {
    background-color:#fcac5c;
}
.item-r2 {
    background-color:#cc0066;
}
.top_case_title{
    font-family: "simhei","Microsoft Yahei","Times New Roman",Georgia,Serif;
    font-size: 20px;
    font-weight:bold;
}
.infodiv-font{
    margin-top:20px;
    font-size:16px;
}
.infodiv-font2{
    margin:20px 0 40px 0;
    font-size:16px;
}
.infoimg{
    margin-right:15px;
    width:88px;
    height:88px;
    border-radius: 10px;
}
.infodiv {
    margin-bottom: 14px;
    padding: 25px 0 9px 0;
    display: flex;
}
.casedv{
    margin:8px 0 3px 6px;
}
// 底部信息栏
.bottom_case {
    white-space: nowrap;
    display: flex;
    margin-left:20px;
    padding-top:5px;
}
.bottom_case_left{
    width: 49%;
}
.bottom_case_right{
    margin-left:20px;
    width: 48%;
}
.list {
  height: 190px;
}
.list li{
    height:30px;
    line-height:30px;
    font-size:16px;
    border-bottom:1px dotted #EEE;
    margin-bottom:5px;
    padding:0 10px 0 5px;
}
.list li span{
    float:right;
    color:#999;
}
.addtime{
  font-size:12px;
  color: #999;
  margin-top:20px;
  text-align:right;
  width:90%;
}
.info-content{
  font-size:16px;
  line-height:32px;
}
</style>